// @ts-ignore
import HomePageLayout from '@sprinx/cvo-eshop-ui-common/pages/HomePage/HomePageLayout';
import React from 'react';
import { AfterPage, AfterPageProps } from '../../@sprinx/after-razzle';
import commonData from '../../api/commonData';
import homepageData from '../../api/homepageData';
import AppLayout from '../../layouts/AppLayout';
import { HomePageData } from '../../types/HomePageData';
import ErrorPage from '../ErrorPage';
import NotFoundPage from '../NotFoundPage';

export interface HomePageProps extends AfterPageProps<HomePageRouteParams> {
  data?: HomePageData;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface HomePageRouteParams {}

const HomePage: AfterPage<HomePageRouteParams, HomePageProps> = ({ data, error }) => {
  if (error) return <ErrorPage error={error} />;
  if (!data) return <NotFoundPage />;

  if (!data) return <div>CHYBA</div>;

  const { htmlHead, news, favoriteCategories, layout } = data;

  return (
    <AppLayout headerProps={layout.header} footerProps={layout.footer}>
      <HomePageLayout htmlHead={htmlHead} news={news} favoriteCategories={favoriteCategories} />
    </AppLayout>
  );
};

HomePage.displayName = 'HomePage';

HomePage.getInitialProps = async () => {
  const layout = await commonData.get();
  const hp = await homepageData.get();

  const data: HomePageData = {
    layout,
    ...hp,
  };

  return {
    data,
  };
};

export default HomePage;
